
.ribbon {
    position: absolute;
    right: -5px; top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px; height: 75px;
    text-align: right;
}
.ribbon span {
    font-size: 20px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 25px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 14px;
    right: -24px;
}

.ribbon1 span {
    font-size: 14px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 25px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 14px;
    right: -24px;
}

.ribbon span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
}
.ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
}

.btn-dashemail .ribbon span{
    background: #333333;
    background: linear-gradient(#2d2d2d 0%, #333333 100%);
}
.btn-dashemail .ribbon span::before{
    border-left: 3px solid #333333;
    border-top: 3px solid #333333;
}
.btn-dashemail .ribbon span::after{
    border-right: 3px solid #333333;
    border-top: 3px solid #333333;
}

.btn-dashsms .ribbon span{
    background: #3274ad;
    background: linear-gradient(#2e6da4 0%, #3274ad 100%);
}
.btn-dashsms .ribbon span::before{
    border-left: 3px solid #3274ad;
    border-top: 3px solid #3274ad;
}
.btn-dashsms .ribbon span::after{
    border-right: 3px solid #3274ad;
    border-top: 3px solid #3274ad;
}

.btn-dashsite .ribbon span{
    background: #4fbbd4;
    background: linear-gradient(#46b1da 0%, #4fbbd4 100%);
}
.btn-dashsite .ribbon span::before{
    border-left: 3px solid #4fbbd4;
    border-top: 3px solid #4fbbd4;
}
.btn-dashsite .ribbon span::after{
    border-right: 3px solid #4fbbd4;
    border-top: 3px solid #4fbbd4;
}

.btn-dashleads .ribbon span{
    background: #3c3169;
    background: linear-gradient(#2a2150 0%, #3c3169 100%);
}
.btn-dashleads .ribbon span::before{
    border-left: 3px solid #3c3169;
    border-top: 3px solid #3c3169;
}
.btn-dashleads .ribbon span::after{
    border-right: 3px solid #3c3169;
    border-top: 3px solid #3c3169;
}

.clear20 {
    clear: both;
    height: 20px;
}