.react-tel-input input[type=text], .react-tel-input input[type=tel] {
    width: 100% !important;
}
.current-packege{
    display: block;
    margin: 20px 0px;
    border: 1px solid #cccccc;
    border-radius: 4px; 
    padding: 10px;
    background-color: #fafafa;
    
}
.current-packege label{
    margin: 15px 0;
    display: inline-block;
    vertical-align: middle;
    width: 25%;
}
.current-packege span.package{
    display: inline-block;
    vertical-align: middle;
    margin: auto;
    width: 75%;
    text-align: right;
}
.current-packege span.package > a{
    font-size: 17px;
    color: #000;
    line-height: 20px;
    font-weight: 500;
}
.current-packege span.package a > strong{
    font-size: 24px;
    color: #8781bd;
    text-align: center;
    margin-top: 3px;
}
.profile-pdfs .page-heading {
    padding: 10px 0;
}
.profile-pdfs .page-heading h3{
    font-size: 15px;
}
.profile-pdfs {
    padding: 20px 20px;
}
div[data-test-id="CircularProgressbarWithChildren__children"] {
    height: 115px !important;
    margin-top: -115px !important;
}
div[data-test-id="CircularProgressbarWithChildren__children"] .file {
    margin: 0 !important;
}