.CircularProgressbar {
    height:115px
}

.heading{
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
}

