.invoice-title h2, .invoice-title h3 {
    display: inline-block;
}

.table > tbody > tr > .no-line {
    border-top: none;
}

.table > thead > tr > .no-line {
    border-bottom: none;
}

.table > tbody > tr > .thick-line {
    border-top: 2px solid;
}

.invoice{
    border-bottom: 1px solid black;
    width: 100px;
}
.invoice-title {
    margin-bottom: 10px;
}

.tb-heigh-450{
    max-height: 200px;
}