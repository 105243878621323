.date-input {
    background: #f1f1f1;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    outline: none;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 6px 35px 6px 10px;
    position: relative;
    transition: all 0.3s ease-in-out;
    margin-right: 5px;
}